.slide {
  height: 320px;
}

.slide-box {
  width: 75%;
}

@media screen and (min-width: 480px) {
  .slide {
    height: 360px;
  }
}

@media screen and (min-width: 600px) {
  .slide {
    height: 420px;
  }

  .slide-box {
    width: 58.3%;
  }
}

@media screen and (min-width: 640px) {
  .slide {
    height: 500px;
  }
  .slide-box {
    width: 55%;
  }
}

@media screen and (min-width: 768px) {
  .slide-box {
    width: 60%;
  }
  .slide {
    height: 403px;
  }
}

@media screen and (min-width: 981px) {
  .slide {
    height: 451px;
  }
}

@media screen and (min-width: 1068px) {
  .slide-box {
    width: 66%;
  }
  .slide {
    height: 530px;
  }
}

@media screen and (min-width: 1405px) {
  .slide {
    height: 600px;
  }
}
