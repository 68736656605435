.font-pilsner {
  font-family: 'pilsner', sans-serif;
}

.partner:visited {
  color: gray;
}

.partner:hover,
.partner:active {
  color: #e50000;
}
